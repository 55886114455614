import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Offcanvas, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { CategoryTree } from "../components/category-tree/category-tree";
import { LoadingWrapper } from "../components/layout/loading-wrapper";
import { PageTitleBlock } from "../components/layout/page-title-block";
import { getPageItems } from "../components/pagination/helpers";
import { PageBottomPagination } from "../components/pagination/page-bottom-pagination";
import { PageHeaderPagination } from "../components/pagination/page-header-pagination";
import { ProductCard } from "../components/product-card/product-card";
import { ICategory } from "../models/content/category/ICategory";
import { IPageableState } from "../models/content/pagination/IPageableState";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useGetProductsQuery } from "../api/ecosternApi";
import { selectIsStoreFiltersOpen, toggleFilterCategory, toggleStoreFilters } from "../store/slices/uiSlice";
import { selectCurrentClientId, selectIsShowOnlyFavoriteProducts } from "../store/slices/userSlice";
import { searchItems } from "../utils/utils";
import { useProductInQuery } from "components/modals/product-modal";
import { useSearch } from "components/header/navigation/search-bar";
import { getProductsByTag, getUIAttributes } from "utils/product.utils";
import { UIProduct } from "models/content/product/UIProduct";
import { NestedKeys } from "types/NestesKeys";
import { useAnalytics } from "hooks/use-analytics";

enum SortOption {
    Default = "products.sortOption.default",
    LowerPriceFirst = "products.sortOption.lowerPriceFirst",
    HigherPriceFirst = "products.sortOption.higherPriceFirst",
    AlphabeticallyAtoZ = "products.sortOption.alphabeticallyAtoZ",
    AlphabeticallyZtoA = "products.sortOption.alphabeticallyZtoA",
}

interface IStoreViewState extends IPageableState {
    products: UIProduct[];
    filteredProducts: UIProduct[];
    currentPage: number;
    pageSize: number;
    totalItems: number;
    selectedCategory: ICategory | null;
    sortOption: SortOption;
    showOnlySelected: boolean;
    hasSelectedProducts: boolean | undefined;
}

type SearchProps = NestedKeys<UIProduct>;

const MAX_PRIORITIZED_PRODUCTS = 3;

export const StoreView = () => {
    const selectedClientId = useAppSelector(selectCurrentClientId);
    const isShowOnlyFavoriteProducts = useAppSelector(selectIsShowOnlyFavoriteProducts);
    const { data: productsData, isLoading, isError } = useGetProductsQuery(selectedClientId ?? skipToken);
    const { query } = useSearch();
    const { track } = useAnalytics();
    const searchProps: (SearchProps | { name: keyof UIProduct; weight: number })[] = [
        "name",
        "code",
        {
            name: "info",
            weight: 3,
        },
        "uiAttributes.campaign.label",
        "uiAttributes.badges.label",
    ];
    const isFilterOpen = useAppSelector(selectIsStoreFiltersOpen);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const { handleProductIdInQuery } = useProductInQuery();

    const data: UIProduct[] = useMemo(() => {
        const res = productsData?.map((p) => {
            const uiAttributes = getUIAttributes(p, intl);

            return {
                ...p,
                uiAttributes,
            };
        });

        return res ?? [];
    }, [productsData]);

    const isMissingInStockData = useMemo(() => {
        return data.findIndex((p) => !!p.inStock) == -1;
    }, [data]);

    const [state, setState] = useState<IStoreViewState>({
        products: [],
        filteredProducts: [],
        selectedCategory: null,
        currentPage: 1,
        pageSize: 30,
        totalItems: 0,
        sortOption: SortOption.Default,
        showOnlySelected: true,
        hasSelectedProducts: undefined,
    });

    useEffect(() => {
        if (isError) return;

        let mounted = true;
        useEffectHandler({}, mounted);

        return () => {
            mounted = false;
        };
    }, [data, isLoading, isError, state.filteredProducts, isFilterOpen, state.sortOption, state.showOnlySelected]);

    useEffect(() => {
        // Reset the page when search query changes
        useEffectHandler({ currentPage: 1 });

        if (!query) return;
        track("search", { props: { search_query: query } });
    }, [query]);

    const useEffectHandler = (partialState: Partial<IStoreViewState>, mounted = true) => {
        if (isLoading || !mounted) return;

        let hasSelectedProducts = state.hasSelectedProducts;
        let showOnlySelected = state.showOnlySelected;
        if (typeof state.hasSelectedProducts === "undefined") {
            hasSelectedProducts = data.filter((p) => p.isSelected).length > 0;
            showOnlySelected = hasSelectedProducts;
        }

        const products = getFilteredProducts();
        const totalItems = products.length;
        setState({ ...state, ...partialState, products, totalItems, hasSelectedProducts, showOnlySelected });

        handleProductIdInQuery();
    };

    const getFilteredProducts = (keepAllProducts = false) => {
        const viewProducts = state.selectedCategory ? state.filteredProducts : data;
        let products = state.showOnlySelected && !keepAllProducts ? viewProducts.filter((p) => p.isSelected) : viewProducts;
        if (state.showOnlySelected && !keepAllProducts) {
            const prioritizedProductsInSelected = getProductsByTag(products, "prio").length;
            if (prioritizedProductsInSelected < MAX_PRIORITIZED_PRODUCTS) {
                const prioritizedProducts = getProductsByTag(viewProducts, "prio").slice(0, MAX_PRIORITIZED_PRODUCTS - prioritizedProductsInSelected);
                products = [...prioritizedProducts, ...products];
            }
        }
        products = searchItems<UIProduct, SearchProps>(searchProps, products, query);
        products = sortProducts(products);

        return products;
    };

    const sortProducts = (products: UIProduct[]) => {
        const _products = [...products];

        switch (state.sortOption) {
            case SortOption.LowerPriceFirst:
                return _products.sort((a, b) => (a.price > b.price ? 1 : -1));
            case SortOption.HigherPriceFirst:
                return _products.sort((a, b) => (a.price < b.price ? 1 : -1));
            case SortOption.AlphabeticallyAtoZ:
                return _products.sort((a, b) => a.name.localeCompare(b.name));
            case SortOption.AlphabeticallyZtoA:
                return _products.sort((a, b) => -1 * a.name.localeCompare(b.name));
            default: {
                // In case of default sort, prioritize products with "prio" tag.
                const prioritizedProducts = getProductsByTag(_products, "prio").slice(0, MAX_PRIORITIZED_PRODUCTS);
                const rest = _products.filter((p) => !prioritizedProducts.find((pp) => pp.id === p.id));
                return [...prioritizedProducts, ...rest];
            }
        }
    };

    const onPageClick = (pageNumber: number) => {
        setState({ ...state, currentPage: pageNumber });
    };

    const onCategoryFilterChange = (category?: ICategory) => {
        let selectedCategory: ICategory | null;
        let filteredProducts;

        if (!category || state.selectedCategory?.id == category.id) {
            selectedCategory = null;
            filteredProducts = data.slice();
            dispatch(toggleFilterCategory());
        } else {
            selectedCategory = category;
            filteredProducts = data.filter((p) => p.categoryId.startsWith(category.id));
            const { id, name } = selectedCategory;
            dispatch(toggleFilterCategory({ id, name }));
        }

        // Auto-close filter menu on mobile on category change.
        // Remove when more filters are added.
        dispatch(toggleStoreFilters());

        setState({ ...state, currentPage: 1, filteredProducts, selectedCategory });

        if (!selectedCategory) return;
        track("filter", { props: { filter_category_id: selectedCategory.id, filter_category_name: selectedCategory.name } });
    };

    const onSelectedProductsOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const showOnlySelected = e.currentTarget.checked;
        setState({ ...state, showOnlySelected, currentPage: 1 });
    };

    const getCategoryItemsLabel = () => {
        return `${state.products.length} / ${data.length} (${
            state.selectedCategory ? state.selectedCategory?.name : intl.formatMessage({ id: "products.all" })
        })`;
    };

    const onSortOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const sortOption = e.currentTarget.value as unknown as SortOption;
        setState({ ...state, sortOption });
        track("sort", { props: { sort_type: sortOption } });
    };

    const onShowMoreSuggestedProducts = () => {
        setState({ ...state, showOnlySelected: false });
    };

    const getProduct = (code: string) => {
        return data.find((p) => p.code === code);
    };

    const getContent = () => {
        if (state.products.length < state.pageSize && state.showOnlySelected) {
            const suggestedProducts = getFilteredProducts(true).filter((p) => !state.products.find((sp) => sp.id === p.id));

            if (suggestedProducts.length) {
                return (
                    <div className={`d-flex flex-column justify-content-center align-items-center flex-grow-1 py-5`}>
                        {!state.products.length ? (
                            <div>
                                <FormattedMessage id={"products.noChosenProductsFound"} />
                            </div>
                        ) : (
                            <div className={"d-flex flex-wrap w-100"}>
                                {state.products.map((p) => (
                                    <ProductCard key={p.id} product={p} getProduct={getProduct} showInStock={!isMissingInStockData} />
                                ))}
                            </div>
                        )}
                        <h5 className={"pt-5 pb-3"}>
                            <FormattedMessage id={"products.productSuggestions"} />
                        </h5>
                        <div className={"d-flex flex-wrap justify-content-start w-100"}>
                            {suggestedProducts.slice(0, 4).map((p) => (
                                <ProductCard key={p.id} product={p} getProduct={getProduct} showInStock={!isMissingInStockData} />
                            ))}
                        </div>
                        {suggestedProducts.length > 4 && (
                            <div className={"d-flex flex-column justify-content-center ns"}>
                                <div className={"text-primary pb-3 text-center"}>
                                    <FormattedMessage id={"products.suggestedOptions"} values={{ numberOfProducts: suggestedProducts.length - 3 }} />
                                </div>
                                <Button variant={"outline-primary"} size={"sm"} onClick={onShowMoreSuggestedProducts}>
                                    <FormattedMessage id={"products.suggestedOptionsCta"} />
                                </Button>
                            </div>
                        )}
                    </div>
                );
            }
        }

        if (!state.products.length) {
            return (
                <div className={`d-flex justify-content-center align-items-center flex-grow-1 py-5`}>
                    <FormattedMessage id={"products.noProductsFound"} />
                </div>
            );
        }

        return getPageItems(state.products, state.currentPage, state.pageSize).map((p) => (
            <ProductCard key={p.id} product={p} getProduct={getProduct} showInStock={!isMissingInStockData} />
        ));
    };

    return (
        <>
            <PageTitleBlock routeName={"products"} hasOverlap={true} />
            <Container className={"pb-5 mb-2 mb-md-4"}>
                <Row>
                    <Col lg={4} xl={3} as={"aside"} className={"clear"}>
                        <div className={`offcanvas offcanvas-collapse bg-white w-100 rounded-3 shadow-lg py-1 ${isFilterOpen ? "show" : ""}`}>
                            <Offcanvas.Header closeButton className={"shadow-sm"} onHide={() => dispatch(toggleStoreFilters())}>
                                <Offcanvas.Title>
                                    <FormattedMessage id={"nav.filters"} />
                                    <div className={"fs-sm fw-normal"}>{getCategoryItemsLabel()}</div>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className={"py-grid-gutter px-lg-grid-gutter"}>
                                <CategoryTree handler={onCategoryFilterChange} selected={state.selectedCategory} />
                            </Offcanvas.Body>
                        </div>
                        {isFilterOpen && <div className={`offcanvas-backdrop fade show d-md-none`}></div>}
                    </Col>
                    <Col lg={8} xl={9} as={"section"} className={"d-flex flex-column"}>
                        <div className={"d-flex justify-content-between align-items-center pt-2 pb-3 pb-sm-5"}>
                            <div className={"d-flex flex-wrap align-items-center me-3"}>
                                <div className={"d-flex align-items-center flex-nowrap"}>
                                    <Form.Select size={"sm"} onChange={onSortOptionChange} value={state.sortOption}>
                                        {Object.values(SortOption) /* .filter(so => so !== SortOption.Default) */
                                            .map((v) => (
                                                <option key={v} value={v}>
                                                    <FormattedMessage id={v} />
                                                </option>
                                            ))}
                                    </Form.Select>
                                    <span className={"fs-sm text-light opacity-75 text-nowrap ms-2 d-none d-md-block"}>{getCategoryItemsLabel()}</span>
                                </div>
                            </div>
                            {!isShowOnlyFavoriteProducts && state.hasSelectedProducts && (
                                <Form.Check className={"py-3 flex-shrink-0 me-3"}>
                                    <Form.Check.Input
                                        id={"onlySelectedProducts"}
                                        type={"checkbox"}
                                        checked={state.showOnlySelected}
                                        onChange={(e) => onSelectedProductsOptionChange(e)}
                                    ></Form.Check.Input>
                                    <Form.Check.Label htmlFor={"onlySelectedProducts"} className={"opacity-75 text-light fs-sm"}>
                                        <FormattedMessage id={"nav.chosenProducts"} />
                                    </Form.Check.Label>
                                </Form.Check>
                            )}
                            <PageHeaderPagination
                                totalItems={state.totalItems}
                                pageSize={state.pageSize}
                                currentPage={state.currentPage}
                                onPageClick={onPageClick}
                            />
                        </div>
                        <LoadingWrapper isLoading={isLoading} isError={isError}>
                            <Row className={"mx-n2 flex-grow-1 align-content-start"}>{getContent()}</Row>
                            <hr className={"my-3"} />
                            <PageBottomPagination
                                totalItems={state.totalItems}
                                pageSize={state.pageSize}
                                currentPage={state.currentPage}
                                onPageClick={onPageClick}
                                isVisible={!isLoading}
                            />
                        </LoadingWrapper>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
