import { useState, useCallback } from "react";
import { Col, Form, Row, Accordion } from "react-bootstrap";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { ICartProduct } from "../../models/api/ICartProduct";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectCartProducts } from "../../store/slices/cartSlice";
import { useAddCartProductMutation, useGetProductsQuery, useRemoveCartProductMutation, useReplaceCartProductMutation } from "../../api/ecosternApi";
import { getTotalSum } from "../../utils/product.utils";
import { debounce } from "../../utils/utils";
import { LoadingSpinnerButton } from "../ui/buttons/loading-spinner-button";
import { CartzillaIcon, CartzillaIconName } from "../ui/icons/cartzilla-icon";
import { ArchivedProductIndicator } from "../ui/images/product-image/archived-product-icon";
import { ProductImage } from "../ui/images/product-image/product-image";
import { selectCurrentClientId, selectIsUserEcostern } from "store/slices/userSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { toggleProductModal } from "store/slices/uiSlice";
import { useToast } from "hooks/use-toast";
import { IProduct } from "models/api/IProduct";

interface IProps {
    cartProduct: ICartProduct;
    isOutOfStock?: boolean;
    isUnavailable?: boolean;
}

interface ICartProductState {
    quantity: string | null;
}

export const CartProduct = ({ cartProduct, isOutOfStock, isUnavailable }: IProps) => {
    const selectedClientId = useAppSelector(selectCurrentClientId);
    const [removeCartProduct, { isLoading: isRemoveLoading }] = useRemoveCartProductMutation();
    const [addCartProduct, { isLoading: isAddCartProductLoading }] = useAddCartProductMutation();
    const [replaceCartProduct, { isLoading: isReplaceCartProductLoading }] = useReplaceCartProductMutation();
    const cartProducts = useAppSelector(selectCartProducts);
    const { data: allProducts } = useGetProductsQuery(selectedClientId ?? skipToken);
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { addToast } = useToast();
    const isArchived = !cartProduct.product || !cartProduct.product.isAllowedForWeb;

    const [state, setState] = useState<ICartProductState>({
        quantity: null,
    });

    const debouncedUpdateProductQuantity = useCallback(
        debounce((val: string) => {
            const newQuantity = parseInt(val);
            if (isNaN(newQuantity)) return;

            const product = cartProducts.filter((p) => p.productId == cartProduct.productId)[0];
            if (!product) return;

            const newCartProduct = { ...product };
            newCartProduct.quantity = newQuantity > 0 ? newQuantity : 0;
            addCartProduct(newCartProduct);
        }, 500),
        []
    );

    const onProductQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        setState({ ...state, quantity: val });
        debouncedUpdateProductQuantity(val);
    };

    const onProductQuantityClick = (i: -1 | 1) => {
        const currentQuantity = typeof state.quantity === "string" ? parseInt(state.quantity) : cartProduct.quantity;
        const quantity = currentQuantity + i;
        if (isNaN(quantity) || (currentQuantity <= 1 && quantity < currentQuantity)) return;
        setState({ ...state, quantity: quantity.toString() });
        debouncedUpdateProductQuantity(quantity.toString());
    };

    const onReplaceWithAnalogue = async (product: IProduct) => {
        try {
            await replaceCartProduct({ productId: cartProduct.productId, replacementProductId: product.id }).unwrap();
            addToast(
                intl.formatMessage({ id: "shoppingCart.cartProduct.successfullyAdded" }, { productName: product.name }),
                "success",
                intl.formatMessage({ id: "shoppingCart.cartProduct.successfullyReplaced" })
            );
        } catch (e) {
            console.error(e);
            addToast(intl.formatMessage({ id: "error.genericMessage" }), "danger", intl.formatMessage({ id: "shoppingCart.cartProduct.failedReplacement" }));
        }
    };

    const getPotentialAnalogues = () => {
        if (!isArchived || !cartProduct.product?.analogueProducts?.length || !allProducts?.length) return null;

        const analogueProducts = allProducts.filter((p) => cartProduct.product?.analogueProducts?.includes(p.id));

        return (
            <Row className={"pt-3"}>
                <Col xs={{ offset: 0 }} md={{ offset: 0 }}>
                    <Accordion defaultActiveKey={`${cartProduct.productId}-analogues`} className={"accordion-sm"}>
                        <Accordion.Item eventKey={`${cartProduct.productId}-analogues`}>
                            <Accordion.Header>
                                <span className={"fs-sm"}>
                                    <FormattedMessage id={"shoppingCart.cartProduct.potentialAnalogues"} />
                                </span>
                            </Accordion.Header>
                            <Accordion.Body className={"py-2"}>
                                <Row className={"gap-1"}>
                                    {analogueProducts.map((product) => (
                                        <Col
                                            key={`${product.id}-analogues-${cartProduct.productId}`}
                                            xs={12}
                                            className={"d-flex gap-1 align-items-center justify-content-between"}
                                        >
                                            <div
                                                onClick={() => dispatch(toggleProductModal({ product, hasParams: false }))}
                                                className={"d-flex align-items-center gap-3 cursor-pointer "}
                                            >
                                                <ProductImage id={product.id} item={product} size={"sm"} useZoom={false} />
                                                <span className={"fs-md"}>{product.name}</span>
                                            </div>
                                            <div>
                                                <LoadingSpinnerButton
                                                    size={"sm"}
                                                    variant={"secondary"}
                                                    className={""}
                                                    onClick={() => onReplaceWithAnalogue(product)}
                                                    isLoading={isReplaceCartProductLoading}
                                                >
                                                    <FormattedMessage id={"shoppingCart.cartProduct.replace"} />
                                                </LoadingSpinnerButton>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        );
    };

    return (
        <div className={"pt-5"}>
            <Row className={"align-items-start"}>
                <Col xs={3} md={"auto"}>
                    <ProductImage item={cartProduct.product} id={cartProduct.productId} size={"lg"} />
                </Col>
                <Col xs={8} md={4} className={"flex-grow-1"}>
                    <div>
                        <h3 className={"product-title fs-base mb-1"}>{cartProduct.product?.name}</h3>
                        <div className={"fs-sm"}>
                            <span className={"text-muted me-2"}>Kood:</span>
                            <span>{cartProduct.product?.code}</span>
                        </div>
                        <div className={"fs-sm pb-1"}>
                            <span className={"text-muted me-2"}>Hind:</span>
                            <FormattedNumber value={cartProduct.product?.price || 0} style={"currency"} currency={"EUR"} />
                        </div>
                        <div className={"d-flex d-md-none pt-2 d-flex align-items-center"}>
                            <span className={"text-muted fs-sm me-2"}>Summa</span>
                            <div className={"text-accent"}>
                                <FormattedNumber value={getTotalSum(cartProduct.quantity, cartProduct.product?.price)} style={"currency"} currency={"EUR"} />
                            </div>
                        </div>
                        {/* {isOutOfStock && !isArchived ? (
                            <div className={"text-muted fs-sm d-flex align-items-center"}>
                                <CartzillaIcon icon={CartzillaIconName.Announcement} />
                                <span className={"ms-1"}>
                                    &nbsp; <FormattedMessage id={"orderContents.stockSmallerThanOrderAmount"} />
                                </span>
                            </div>
                        ) : null} */}
                        {isArchived && <ArchivedProductIndicator message={intl.formatMessage({ id: "orderContents.productArchived" })} variant={"text"} />}
                        {isUnavailable && !isArchived && (
                            <ArchivedProductIndicator message={intl.formatMessage({ id: "orderContents.productUnavailable" })} variant={"text"} />
                        )}
                    </div>
                </Col>
                <Col md={2} className={"d-none d-md-flex flex-md-column"}>
                    <h3 className={"product-title fs-base pt-2 pt-md-0 me-2"}>Summa</h3>
                    <div className={"fs-lg text-accent mt-1"}>
                        <FormattedNumber value={getTotalSum(cartProduct.quantity, cartProduct.product?.price)} style={"currency"} currency={"EUR"} />
                    </div>
                </Col>
                <Col
                    xs={{ span: 5, offset: 3 }}
                    md={{ span: 2, offset: 0 }}
                    className={"d-flex flex-md-column align-items-center justify-content-start mt-2 mt-md-0"}
                >
                    <h3 className={"product-title fs-base me-2 d-none d-md-flex"}>Kogus</h3>
                    <div className={"d-flex"}>
                        <LoadingSpinnerButton
                            variant={"secondary"}
                            className={"me-1"}
                            size={"sm"}
                            onClick={() => onProductQuantityClick(-1)}
                            isLoading={isAddCartProductLoading}
                            disabled={(typeof state.quantity === "string" ? parseInt(state.quantity) : cartProduct.quantity) <= 1}
                        >
                            {!isAddCartProductLoading && "-"}
                        </LoadingSpinnerButton>
                        <Form.Control
                            type={"number"}
                            style={{ width: "3rem" }}
                            min={1}
                            size={"sm"}
                            value={typeof state.quantity === "string" ? state.quantity : cartProduct.quantity}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onProductQuantityChange(e)}
                        />
                        <LoadingSpinnerButton
                            variant={"secondary"}
                            size={"sm"}
                            className={"ms-1"}
                            onClick={() => onProductQuantityClick(1)}
                            isLoading={isAddCartProductLoading}
                        >
                            {!isAddCartProductLoading && "+"}
                        </LoadingSpinnerButton>
                    </div>
                </Col>
                <Col xs={4} md={2} className={"d-flex flex-md-column align-self-start d-flex justify-content-end align-items-center mt-2 mt-md-0"}>
                    <div className={"d-none d-md-flex"} style={{ height: "1rem", marginBottom: "0.75rem" }}></div>
                    <div className={"d-flex align-items-center"}>
                        <LoadingSpinnerButton
                            isLoading={isRemoveLoading}
                            icon={<CartzillaIcon icon={CartzillaIconName.CloseCircle} />}
                            onClick={() => removeCartProduct(cartProduct.productId)}
                            variant={"link"}
                            className={"px-0 btn"}
                        >
                            <span className="fs-sm">
                                <FormattedMessage id={"product.productRemove"} />
                            </span>
                        </LoadingSpinnerButton>
                    </div>
                </Col>
            </Row>
            {getPotentialAnalogues()}
        </div>
    );
};
