import { Container, Row, Col, Carousel } from "react-bootstrap";
import { PageTitleBlock } from "components/layout/page-title-block";
import { ProductCard } from "components/product-card/product-card";
import { useGetProductsQuery, useGetPublicCampaignsQuery } from "api/ecosternApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "store/hooks";
import { selectCurrentClientId, selectUserWelcomeMessage } from "store/slices/userSlice";
import { useEffect, useMemo, useState } from "react";
import { IPageableState } from "models/content/pagination/IPageableState";
import { PageBottomPagination } from "components/pagination/page-bottom-pagination";
import { getPageItems } from "components/pagination/helpers";
import { LoadingWrapper } from "components/layout/loading-wrapper";
import { FormattedMessage, useIntl } from "react-intl";
import { IStoreProduct } from "../../models/api/IProduct";
import { Link, useLocation } from "react-router-dom";
import { ICampaign } from "models/api/ICampaign";
import { useProductInQuery } from "components/modals/product-modal";
import { routes } from "router";
import { useAnalytics } from "hooks/use-analytics";
import { useIsSeen } from "hooks/use-is-seen";
import { IPublicCampaign, IPublicCampaignImage } from "models/api/IPublicCampaign";
import React from "react";
import { useForwardedRef } from "hooks/use-forwarded-ref";
import { getProductsByTag } from "utils/product.utils";

interface IViewProductsState extends IPageableState {
    products: IStoreProduct[];
}

type PageSection = "new" | "special";

// eslint-disable-next-line react/display-name
const CarouselItem = React.forwardRef<HTMLDivElement, { c: IPublicCampaign; campaignPath: string; type: IPublicCampaignImage["type"] }>(
    ({ c, campaignPath, type, ...rest }, ref) => {
        const [innerRef] = useForwardedRef(ref);
        const { track } = useAnalytics();
        useIsSeen(innerRef, () => track("campaign_view"));

        const fileId = c.images.find((f) => f.type === type)?.fileId;
        if (!fileId) return null;

        return (
            <Carousel.Item ref={innerRef} key={fileId} {...rest}>
                <Link to={{ pathname: campaignPath }} onClick={() => track("campaign_click")}>
                    <img src={`${process.env.REACT_APP_API_HOST}/api/v1/public/files/${fileId}`} />
                </Link>
            </Carousel.Item>
        );
    }
);

export const HomePrivateView = () => {
    const selectedClientId = useAppSelector(selectCurrentClientId);
    const welcomeMessage = useAppSelector(selectUserWelcomeMessage);
    const { state } = useLocation() as { state: { campaign: ICampaign } | undefined };
    const campaigns = useGetPublicCampaignsQuery();
    const { data = [], isLoading, isError } = useGetProductsQuery(selectedClientId ?? skipToken);
    const { handleProductIdInQuery } = useProductInQuery();
    const intl = useIntl();

    const isMissingInStockData = useMemo(() => {
        return data.findIndex((p) => !!p.inStock) == -1;
    }, [data]);

    const [newProductsState, setNewProductsState] = useState<IViewProductsState>({
        totalItems: 0,
        currentPage: 1,
        pageSize: 6,
        products: [],
    });

    const [notableProductsState, setNotableProductsState] = useState<IViewProductsState>({
        totalItems: 0,
        currentPage: 1,
        pageSize: 6,
        products: [],
    });

    useEffect(() => {
        let mounted = true;

        if (data && !isError && mounted) {
            const newProducts = getProductsByTag(data, "uus");
            const notableProducts = getProductsByTag(data, "eri");

            setNewProductsState({ ...newProductsState, totalItems: newProducts.length, currentPage: 1, products: newProducts });
            setNotableProductsState({ ...notableProductsState, totalItems: notableProducts.length, currentPage: 1, products: notableProducts });

            handleProductIdInQuery();
        }

        return () => {
            mounted = false;
        };
    }, [isLoading, isError]);

    const onPageClick = (pageNumber: number, section: PageSection) => {
        if (section === "new") {
            setNewProductsState({ ...newProductsState, currentPage: pageNumber });
        }
        if (section === "special") {
            setNotableProductsState({ ...notableProductsState, currentPage: pageNumber });
        }
    };

    const getContent = (section: PageSection) => {
        const state = section === "new" ? newProductsState : notableProductsState;
        const titleKey = section === "new" ? "home.newProducts" : "home.specialOffers";
        if (state.products.length) {
            return (
                <section className={`${section === "new" ? "bg-secondary" : ""}`}>
                    <Container className={"py-5 mb-md-4"}>
                        <Row className={"mb-3"}>
                            <h4 className={"mb-3"}>
                                <FormattedMessage id={titleKey} />
                            </h4>
                            <Col lg={12} as={"section"} className={"d-flex flex-wrap"}>
                                {getPageItems(state.products, state.currentPage, state.pageSize).map((p) => (
                                    <ProductCard
                                        product={p}
                                        key={p.id}
                                        col={4}
                                        variant={section === "new" ? "transparent" : undefined}
                                        showInStock={!isMissingInStockData}
                                    />
                                ))}
                                {/* <hr className="my-3" /> */}
                                <PageBottomPagination
                                    totalItems={state.totalItems}
                                    pageSize={state.pageSize}
                                    currentPage={state.currentPage}
                                    onPageClick={(i: number) => onPageClick(i, section)}
                                    doScrollTop={false}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }

        return <></>;
    };

    const getCampaign = () => {
        const allCampaigns = state?.campaign ? [state.campaign] : campaigns.data;
        const campaignPath = `${routes.products.path}?q=${intl.formatMessage({ id: "product.campaign.regularCampaign" }).replace(/[\W]/g, "").toLowerCase()}`;
        if (!allCampaigns?.length) return null;

        return (
            <Container className={"pt-5"}>
                {/* Desktop */}
                <Carousel className={"d-none d-md-block"} prevIcon={""} nextIcon={""}>
                    {allCampaigns
                        .filter((c) => c.images.filter((f) => f.type === "DESKTOP"))
                        .map((c, i) => (
                            <CarouselItem key={"desktop-img" + i} c={c} campaignPath={campaignPath} type={"DESKTOP"} />
                        ))}
                </Carousel>
                {/* Mobile */}
                <Carousel className={"d-block d-md-none"} prevIcon={""} nextIcon={""}>
                    {allCampaigns
                        .filter((c) => c.images.filter((f) => f.type === "MOBILE"))
                        .map((c, i) => (
                            <CarouselItem key={"mobile-img" + i} c={c} campaignPath={campaignPath} type={"MOBILE"} />
                        ))}
                </Carousel>
            </Container>
        );
    };

    return (
        <>
            <PageTitleBlock routeName={"home"} hasLinkToStore />
            <section className={"bg-secondary"}>
                <div className={"container p-3 text-center"}>{welcomeMessage}</div>
            </section>
            {/* {state.currentPage == 1 && <CampaignCarousel />} */}
            <LoadingWrapper isLoading={isLoading} isError={isError}>
                {getCampaign()}
                {getContent("special")}
                {getContent("new")}
            </LoadingWrapper>
        </>
    );
};
