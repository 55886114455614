import React, { useEffect, useRef } from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";

interface IProps {
    children?: React.ReactNode;
    isLoading: boolean;
    icon?: React.ReactNode;
    hasSharedLoadingControl?: boolean;
    useLastTargetInAnimationControl?: boolean;
}

let LAST_TARGET: HTMLButtonElement | null = null;

export const LoadingSpinnerButton = ({ children, icon, className, useLastTargetInAnimationControl = true, ...props }: IProps & ButtonProps) => {
    const ref = useRef<HTMLButtonElement>(null);
    const isLoading = useLastTargetInAnimationControl ? props.isLoading && LAST_TARGET === ref.current : props.isLoading;

    useEffect(() => {
        if (!useLastTargetInAnimationControl) return;

        const handleEvent = (e: MouseEvent) => (LAST_TARGET = e.currentTarget as HTMLButtonElement);
        ref.current?.addEventListener("click", (e) => handleEvent(e));
        return () => ref.current?.removeEventListener("click", (e) => handleEvent(e));
    }, []);

    return (
        <Button ref={ref} className={`d-flex align-items-center ${className}`} {...props}>
            {isLoading && <Spinner animation={"border"} size={"sm"} />}
            {!isLoading && icon && icon}

            {(icon && children) || (isLoading && children) ? <span className={"ms-2"}>{children}</span> : children}
        </Button>
    );
};
